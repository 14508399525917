<template>
  <div id="app">
    <el-container>
      <el-header height="95px">
        <Header />
      </el-header>
      <el-main><router-view /></el-main>
      <el-footer :height="$store.state.note.userAgent == 1 ? '292px' : '420px'">
        <Footer />
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import Header from "@/views/components/header/index.vue";
import Footer from "@/views/components/footer/index.vue";
// import { resize } from "@/utils/rem";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isCollapse: false,
      isShrink: false,
      list: [],
    };
  },
  created() {
    // window.onresize = function () {
    //   resize();
    // };
    // resize();
  },
  methods: {

  },
};
</script>
<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // color: $primary;
  font-family: "LINE Seed JP_OTF";

  .el-container {
    overflow: hidden;

    .el-header {
      background-color: #051658;
      color: #fff;
      text-align: center;
      line-height: 60px;
      padding: 0;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
    }

    .el-footer {
      background-color: #303030;
      color: #fff;
      text-align: center;
      line-height: 60px;
      padding: 0px;
    }

    .el-main {
      position: relative;
      background: #F5F3EE;
      width: 100%;
      height: auto;
      padding: 95px 0 0 0;
      overflow: auto;
    }

  }
}
</style>
