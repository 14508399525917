const API_LIST = {
  user: {login: 'users/managerLogin.show'},
  common: {
    list:'venuesAppointment/getVenues.show',
  },
  export:{
    docx:''
  }
}
export default API_LIST
