import service from '@/http/HttpInterceptor.js'
import API_LIST from '@/api/config'
/**
 *
 */

export const postUserList = (params) => {
  return service({
    url: API_LIST.user.login,
    method: 'post',
    data: params
  })
}
export default Object.assign({
  postUserList
})
