/*
 * @Author:
 * @Date:
 * @Last Modified by:
 * @Last Modified time:
 */
import axios from "axios";
import promise from "es6-promise";
import { Loading } from "element-ui";
promise.polyfill();
let loadingInstance;
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api 的 base_url
  // baseURL:"http://lht-local.com",
  timeout: 50000, // request timeout
});
// 请求拦截
service.interceptors.request.use(
  (config) => {
    loadingInstance = Loading.service({ fullscreen: true });
    // 接口请求加密
    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

// 返回值拦截
service.interceptors.response.use(
  (response) => {
    if (loadingInstance) {
      loadingInstance.close();
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default service;
