import Vue from "vue";
import VueRouter from "vue-router";
import { route } from "./modules/routes";
import { mobile } from "./modules/mobile";
import { systemAgent } from "../utils/index";

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const userRoutes = systemAgent() == 1 ? route : mobile;
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  // base: process.env.VUE_APP_BASE_API,
  routes: userRoutes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  // router.push({name: router.currentRoute.name })
  next();
});
export default router;
