import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import apis from "./api/service";

import components from "@/components"; // global module
import "@/style/index.scss"; // global css

import { VueJsonp } from "vue-jsonp";
// 懒加载
import VueLazyload from "vue-lazyload";
import lazyImg from "@/assets/img/load.png";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

// import JsonExcel from "vue-json-excel";

import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

// Vue.component("downloadExcel", JsonExcel);

Vue.use(ElementUI).use(components).use(apis).use(VueJsonp).use(VueLazyload, {
  preLoad: 2,
  error: lazyImg,
  loading: lazyImg,
  attempt: 1,
});
Vue.config.productionTip = false;

const userAgent = navigator.userAgent;
if (/(Windows|Macintosh)/.test(userAgent)) {
  console.log("当前系统为Windows或者Mac OS X");
  store.commit("SET_USERAGENT", 1);
} else if (/Android/.test(userAgent)) {
  store.commit("SET_USERAGENT", 2);
  console.log("当前系统为Android");
} else if (/iPhone|iPad|iPod/.test(userAgent)) {
  store.commit("SET_USERAGENT", 2);
  console.log("当前系统为iOS");
} else {
  store.commit("SET_USERAGENT", 1);
  console.log("无法确定当前系统");
}
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
