/*
 * @Author:
 * @Date:
 * @Last Modified by:
 * @Last Modified time:
 */
import { postUserList } from '@/api/interface/interface-list/user'
import { getCommonList } from '@/api/interface/interface-list/common'
import { menu } from '@/router/modules/menu.js'

const app = {
  state: {
    userInfo: JSON.parse(localStorage.getItem("userInfo")),
    ThemeColor: {
      menuBgColor:'#2f4055',
      textColor:'#fff',
      activeColor:'#ffd04b',
      mainBgColor:'#f7f7f7'
    },
    submenu:menu[0].children,
    currentRoute:[]

  },

  mutations: {
    SET_USERINFO: (state, userinfo) => {
      state.userInfo = userinfo
    },
    SUBMENU: (state, submenu) => {
      state.submenu = submenu
    },
    CURRENTROUTE: (state, currentRoute) => {
      state.currentRoute = currentRoute
    },
  },
  actions: {
    // 用户登录
    login(userInfo) {
      return new Promise((resolve, reject) => {
        postUserList(userInfo).then(response => {
          const { data } = response
          if (data.status.returnCode === 100) {
            commit('SET_USERINFO', response)
          }
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
export default app
