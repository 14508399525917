import service from "@/http/HttpInterceptor.js";

export const getLineCompany = (params) => {
  return service({
    url: "/api/routeStatus/lineCompany",
    method: "GET",
    params: params,
  });
};
export const getLineStation = (params) => {
  return service({
    url: "/api/routeStatus/lineStation",
    method: "POST",
    data: params,
  });
};
export const postEmailReserve = (params) => {
  return service({
    url: "/api/email/reserve",
    method: "POST",
    data: params,
  });
};
export const postEmailConsult = (params) => {
  return service({
    url: "/api/email/consult",
    method: "POST",
    data: params,
  });
};
export const getAreaList = (params) => {
  return service({
    url: "/api/area/index",
    method: "GET",
    params: params,
  });
};
export const postDetailAreaImage = (params) => {
  return service({
    url: "/api/area/detailAreaImage",
    method: "POST",
    data: params,
  });
};
export const postLayout = (params) => {
  return service({
    url: "api/office/layout",
    method: "POST",
    data: params,
  });
};
export const postProperty = (params) => {
  return service({
    url: "api/property/index",
    method: "POST",
    data: params,
  });
};
export const getStatiscs = (params) => {
  return service({
    url: "api/office/statistics",
    method: "GET",
    params: params,
  });
};
export const getSingleStatistics = (params) => {
  return service({
    url: "api/office/singleStatistics",
    method: "POST",
    data: params,
  });
};
export const getMunicipalitiesMasterList = (params) => {
  return service({
    url: "api/area/municipalitiesMasterList",
    method: "POST",
    data: params,
  });
};
export const getStreetMasterList = (params) => {
  return service({
    url: "api/area/streetMasterList",
    method: "POST",
    data: params,
  });
};
export const getShopList = (params) => {
  return service({
    url: "api/office/shop",
    method: "POST",
    data: params,
  });
};

export default Object.assign({
  getLineCompany,
  getLineStation,
  postEmailReserve,
  postEmailConsult,
  getAreaList,
  postDetailAreaImage,
  postLayout,
  postProperty,
  getStreetMasterList,
  getMunicipalitiesMasterList,
  getStatiscs,
  getSingleStatistics,
  getShopList,
});
