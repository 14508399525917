export const mobile = [
    {
      path: "/",
      name: "mobileindex",
      component: () => import("@/views/home/mobileIndex.vue"),
    },
    {
      path: "/mmove",
      name: "mmove",
      component: () => import("@/views/mobile/mmove.vue"),
    },
    {
      path: "/mterms",
      name: "mterms",
      component: () => import("@/views/mobile/mterms.vue"),
    },
    {
      path: "/mmonster",
      name: "mmonster",
      component: () => import("@/views/mobile/mmonster.vue"),
    },
    {
      path: "/mconsult",
      name: "mconsult",
      component: () => import("@/views/mobile/mconsult.vue"),
    },
    {
      path: "/minApply",
      name: "minApply",
      component: () => import("@/views/mobile/minApply.vue"),
    },
    {
      path: "/mconfirm",
      name: "mconfirm",
      component: () => import("@/views/mobile/mconfirm.vue"),
    },
    {
      path: "/mquestion",
      name: "mquestion",
      component: () => import("@/views/mobile/mquestion.vue"),
    },
    {
      path: "/mperinfo",
      name: "mperinfo",
      component: () => import("@/views/mobile/mperinfo.vue"),
    },
    {
      path: "/mservice",
      name: "mservice",
      component: () => import("@/views/mobile/mservice.vue"),
    },
    {
      path: "/mlink",
      name: "mlink",
      component: () => import("@/views/mobile/mlink.vue"),
    },
    {
      path: "/mcomment",
      name: "mcomment",
      component: () => import("@/views/mobile/mcomment.vue"),
    },
    {
      path: "/msend",
      name: "msend",
      component: () => import("@/views/mobile/msend.vue"),
    },
    
  ]
  