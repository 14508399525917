/*
 * @Description: 引入组件
 * @Date: 2021-05-21 18:23:19
 * @LastEditTime: 2023-07-03 09:29:25
 *  
 * 
 */
import PagePostion from "./Home";
// import Array from "./loading"
// 所有组件列表
let components = [
  PagePostion,
];
// components = [...components,...Array]
components = [...components]

// 定义 install 方法，接收 Vue 作为参数
const install = function (Vue) {
  // 判断是否安装，安装过就不继续往下执行
  if (install.installed) return;
  install.installed = true;
  // 遍历注册所有组件
  components.map((component) => Vue.component(component.name, component));
  // 下面这个写法也可以
  // components.map(component => Vue.use(component))
};

// 检测到 Vue 才执行，毕竟我们是基于 Vue 的
if (typeof window !== "undefined" && window.Vue) {
  install(window.Vue);
}
export {
  PagePostion
};

export default {
  install,
  // 所有组件，必须具有 install，才能使用 Vue.use()
  ...components,
};
