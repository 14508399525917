<template>
  <div class="header">
    <div class="box" v-if="$store.state.note.userAgent == 1">
      <div class="logo" @click="goHome()">
        <img src="@/assets/img/logo.png" alt="" />
      </div>
      <div class="nav">
        <div class="nav_item" @click="goNavr(2)">
          <div class="icon"><img src="@/assets/icon/train.png" alt="" /></div>
          <div class="txt">路線駅</div>
        </div>
        <div class="nav_item" @click="goNavr(3)">
          <div class="icon"><img src="@/assets/icon/pin_drop.png" alt="" /></div>
          <div class="txt">住所</div>
        </div>
        <div class="nav_item" @click="goNavr(1)">
          <div class="icon"><img src="@/assets/icon/map.png" alt="" /></div>
          <div class="txt">エリア</div>
        </div>
        <div class="nav_item" @click="goNavr(4)">
          <div class="icon"><img src="@/assets/icon/groups.png" alt=""></div>
          <div class="txt">面積・人数</div>
        </div>
        <div class="nav_item" @click="goSupport()">
          <div class="icon"><img src="@/assets/icon/support_agent.png" alt="" /></div>
          <div class="txt">スッタフに相談</div>
        </div>
        <div class="nav_item" @click="goSearch()">
          <div class="icon"><img src="@/assets/icon/pin.png" alt="" /></div>
          <div class="txt">物件No.</div>
        </div>
        <div class="nav_item">
          <div class="icon"><img src="@/assets/icon/history.png" alt="" /></div>
          <div class="txt">閲覧履歴</div>
        </div>
        <div class="nav_item" @click="goStar()">
          <div class="icon"><img src="@/assets/icon/star_rate.png" alt="" /></div>
          <div class="txt">お気に入り</div>
        </div>
      </div>
    </div>
    <div class="box_phone" v-else>
      <div class="logo"><img src="@/assets/imgs/logoaa.png" alt="" /></div>
      <div class="box_nav">
        <div class="nav_item">
          <div class="item_icon"><img src="@/assets/imgs/sp_menu02.png" alt="" /></div>
          <div class="item_txt">お気に入り</div>
        </div>
        <div class="nav_item" @click="centerDialogVisible = !centerDialogVisible">
          <div class="item_icon" v-if="centerDialogVisible"><img src="@/assets/imgs/sp_menu01.png" alt="" /></div>
          <div class="item_icon" v-else><img src="@/assets/imgs/sp_menu02.png" alt="" /></div>
          <div class="item_txt">メニュー</div>
        </div>
      </div>
      <el-collapse-transition>
        <div class="nav_lists_box" v-if="centerDialogVisible">
          <div class="nav_lists_item">
            <div class="nav_list_icon"><img src="@/assets/menu/map_e.png" alt="" /></div>
            <div class="nav_list_txt"> エリアから探す</div>
          </div>
          <div class="nav_lists_item">
            <div class="nav_list_icon"><img src="@/assets/menu/train_e.png" alt="" /></div>
            <div class="nav_list_txt"> 路線駅から探す</div>
          </div>
          <div class="nav_lists_item">
            <div class="nav_list_icon"><img src="@/assets/menu/pin_drop_e.png" alt="" /></div>
            <div class="nav_list_txt"> 住所から探す</div>
          </div>
          <div class="nav_lists_item">
            <div class="nav_list_icon"><img src="@/assets/menu/dashboard_customize_e.png" alt="" /></div>
            <div class="nav_list_txt"> 面積から探す</div>
          </div>
          <div class="nav_lists_item">
            <div class="nav_list_icon"><img src="@/assets/menu/groups_e.png" alt="" /></div>
            <div class="nav_list_txt"> 使用人数から探す</div>
          </div>
          <div class="nav_lists_item">
            <div class="nav_list_icon"><img src="@/assets/icon/support_agent.png" alt="" /></div>
            <div class="nav_list_txt"> スッタフに相談</div>
          </div>
          <div class="nav_lists_item">
            <div class="nav_list_icon"><img src="@/assets/icon/history.png" alt="" /></div>
            <div class="nav_list_txt"> 閲覧履歴</div>
          </div>
          <div class="nav_list_search">
            <div class="ter_item_icon"><img src="@/assets/menu/pin.png" alt="" /></div>
            <div class="ter_item_txt">
              物件No.
              <b class="sm">で個別検索する</b>
            </div>
            <div class="list_item_input">
              <el-input v-model="search" placeholder="物件No.を入力" @change="goSearch()">
                <i slot="suffix" class="el-input__icon el-icon-search" @click="goSearch()"></i>
              </el-input>
            </div>
          </div>
          <div class="nav_list_search">
            <div class="ter_item_icon"><img src="@/assets/imgs/search.png" alt="" /></div>
            <div class="ter_item_txt">
              フリーワード
              <b class="sm">で検索する</b>
            </div>
            <div class="list_item_input">
              <el-input v-model="search" placeholder="地名･駅名･物件名" @change="goSearch()">
                <i slot="suffix" class="el-input__icon el-icon-search" @click="goSearch()"></i>
              </el-input>
            </div>
          </div>
        </div>

      </el-collapse-transition>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      centerDialogVisible: false,
      search: '',
    };
  },
  mounted() {
    console.log('header')
  },
  methods: {
    goNavr(event) {
      this.$store.commit('SET_HOMENAV', event);
      this.$router.push({ name: 'index' })
    },
    goSupport() {
      // this.$router.push({ name: 'inApply' })
      this.$router.push({ name: 'consult' })
    },
    goSearch() {
      this.$router.push('/search')
    },
    goStar() {
      this.$router.push({ name: 'star' })
    },
    goHome() {
      this.$router.push({ name: 'index' })
    }
  }
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 95px;
  flex-shrink: 0;
  background: #051658;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .box {
    width: 1150px;
    height: 95px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 353px;
      height: 62.871px;
      flex-shrink: 0;
      cursor: pointer;
    }

    .nav {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .nav_item {
        width: 95px;
        height: 62.871px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        cursor: pointer;

        .icon {
          width: 24px;
          height: 24px;
          margin-bottom: 18px;
        }

        .txt {
          width: 100%;
          height: 20px;
          line-height: 20px;
          color: #fff;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: center;
        }

        .active {
          color: #FFD300 !important;
        }
      }
    }
  }

  .box_phone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 95px;
    padding: 0 16px;
    position: relative;

    .logo {
      width: 168px;
      height: 30px;
    }

    .box_nav {
      display: flex;
      align-items: center;
      justify-content: center;

      .nav_item {
        width: 55px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-right: 14px;

        .item_icon {
          width: 24px;
          height: 24px;
        }

        .item_txt {
          font-size: 10px;
          color: #fff;
        }

      }
    }

    .nav_lists_box {
      position: fixed;
      left: 0;
      right: 0;
      top: 95px;
      bottom: 0;
      background: #051658;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .nav_lists_item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 200px;
        // height: 25px;

        .nav_list_icon {
          width: 24px;
          height: 24px;
          padding-bottom: 22px;
        }

        .nav_list_txt {
          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          margin-left: 10px;
        }
      }

      .nav_list_search {
        width: 85%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        border-radius: 16px;
        cursor: pointer;
        background: #fff;
        color: #303030;
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);

        .ter_item_icon {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }

        .ter_item_txt {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          height: 36px;

          .sm {
            font-size: 13px;
          }
        }

        .list_item_input {
          width: 90%;
          margin-top: 5px;
          ::v-deep .el-input__inner {
            border: 1px solid #fff;
            height: 36px;
            line-height: 36px;
            padding-left: 25px;
            border-radius: 20px;
            background: #F5F3EE;
          }
        }
      }
    }
  }
}
</style>
