// import { routers } from "@/utils/map_menus";
import { menu } from './menu'
const routes = [
  // {
  //   path: "/",
  //   name: "index",
  //   component: () => import("@/views/home/index.vue"),
  // },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/search/index.vue"),
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import("@/views/detail/index.vue"),
  },
  {
    path: "/star",
    name: "star",
    component: () => import("@/views/star/index.vue"),
  },
  // {
  //   path: "*",
  //   name: "404",
  //   component: () => import("@/views/components/404.vue"),
  // },
];
export const route = [...routes,...menu]
